export const appBuildAssetsDir = "/_assets/"

export const appRootId = "__xym"

export const appHead = {"meta":[{"charset":"utf-8"},{"http-equiv":"content-type","content":"text/html; charset=utf-8"},{"name":"viewport","content":"user-scalable=no"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"stylesheet","href":"static/uikit@3.15.22/dist/css/uikit.min.css"}],"style":[],"script":[{"src":"static/uikit@3.15.22/dist/js/uikit.min.js"},{"src":"static/uikit@3.15.22/dist/js/uikit-icons.min.js"}],"noscript":[],"title":"新翼梦官网"}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootTag = "div"