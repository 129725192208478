import variables from '@/styles/variables.scss?inline';

export function onResize() {
  const data = {};
  const mobileKey = useMobileKey();
  const desktopKey = useDesktopKey();
  const device = useDevice();
  const variablesScss = computed(() => {
    const obj = {};
    const arr = variables.split('\n').map((v) => v.trim());
    arr.pop();
    arr.shift();
    arr.forEach((v) => {
      v = v.replace(/;/, '');
      const keyValue = v.split(':').map((v) => v.trim());
      obj[keyValue[0]] = keyValue[1];
    });
    return obj;
  });

  onBeforeMount(() => {
    window.addEventListener('resize', $_resizeHandler);
    $_resizeHandler();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', $_resizeHandler);
  });

  function $_resizeHandler() {
    if (!document.hidden) {
      const isMobile = $_isMobile();
      device.value = isMobile ? mobileKey.value : desktopKey.value;
    }
  }

  function $_isMobile() {
    const width = parseFloat(variablesScss.value.mobileMinWidth);
    let isMobile = false;
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      // console.log('移动端');
      isMobile = true;
    }

    const { body } = document;
    const rect = body.getBoundingClientRect();
    isMobile = rect.width - 1 < width;
    return isMobile;
  }

  return data;
}
