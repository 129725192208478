import { default as indexeHU95Xu1W3Meta } from "/wkdir/work/xym-index/pages/index.vue?macro=true";
export default [
  {
    name: indexeHU95Xu1W3Meta?.name ?? "index___en",
    path: indexeHU95Xu1W3Meta?.path ?? "/en",
    children: [],
    meta: indexeHU95Xu1W3Meta,
    alias: indexeHU95Xu1W3Meta?.alias || [],
    redirect: indexeHU95Xu1W3Meta?.redirect || undefined,
    component: () => import("/wkdir/work/xym-index/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHU95Xu1W3Meta?.name ?? "index___zh___default",
    path: indexeHU95Xu1W3Meta?.path ?? "/",
    children: [],
    meta: indexeHU95Xu1W3Meta,
    alias: indexeHU95Xu1W3Meta?.alias || [],
    redirect: indexeHU95Xu1W3Meta?.redirect || undefined,
    component: () => import("/wkdir/work/xym-index/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHU95Xu1W3Meta?.name ?? "index___zh",
    path: indexeHU95Xu1W3Meta?.path ?? "/zh",
    children: [],
    meta: indexeHU95Xu1W3Meta,
    alias: indexeHU95Xu1W3Meta?.alias || [],
    redirect: indexeHU95Xu1W3Meta?.redirect || undefined,
    component: () => import("/wkdir/work/xym-index/pages/index.vue").then(m => m.default || m)
  }
]