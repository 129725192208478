<template>
  <div :class="[device]">
    <NuxtPage />
  </div>
</template>
<script setup>
const { t, locale, locales } = useI18n();
onResize();

const device = useDeviceKey();

// if (locale.value === 'ja') {
//   console.log('loaded jp font');
// } else if (locale.value === 'en') {
//   console.log('loaded en font');
// } else {
//   console.log('loaded zh font');
// }
</script>
<style>
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: 'Noto Sans JP', 'Noto Sans SC', 'Open Sans', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    '微软雅黑', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
</style>
