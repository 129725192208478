import components_plugin_KR1HBZs4kY from "/wkdir/work/xym-index/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_hWlbYirNy4 from "/wkdir/work/xym-index/node_modules/.pnpm/nuxt@3.2.0_@types+node@18.11.18_sass@1.57.1/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_pSYryIPhcY from "/wkdir/work/xym-index/node_modules/.pnpm/nuxt@3.2.0_@types+node@18.11.18_sass@1.57.1/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_LBae8AgHEG from "/wkdir/work/xym-index/node_modules/.pnpm/nuxt@3.2.0_@types+node@18.11.18_sass@1.57.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import composition_yeJFB7SEXS from "/wkdir/work/xym-index/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.9_vue@3.2.47/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_6hnSw56vd3 from "/wkdir/work/xym-index/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.9_vue@3.2.47/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import payload_client_ovWhG8Lekh from "/wkdir/work/xym-index/node_modules/.pnpm/nuxt@3.2.0_@types+node@18.11.18_sass@1.57.1/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import uikit_client_9GTUWsRh1H from "/wkdir/work/xym-index/plugins/uikit.client.ts";
export default [
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_hWlbYirNy4,
  router_pSYryIPhcY,
  prefetch_client_LBae8AgHEG,
  composition_yeJFB7SEXS,
  i18n_6hnSw56vd3,
  payload_client_ovWhG8Lekh,
  uikit_client_9GTUWsRh1H
]